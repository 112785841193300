<script lang="ts">
  import cx from "classnames"
  import {nip19} from "nostr-tools"
  import {tweened} from "svelte/motion"
  import {find, fromPairs, pathEq, identity, sum, pluck, sortBy} from "ramda"
  import {formatSats} from "src/util/misc"
  import {LOCAL_RELAY_URL, isLike, Tags, asNostrEvent, getIdOrNaddr, toNostrURI} from "src/util/nostr"
  import {quantify} from "hurdak"
  import {toast} from "src/partials/state"
  import Popover from "src/partials/Popover.svelte"
  import ColorDot from "src/partials/ColorDot.svelte"
  import Content from "src/partials/Content.svelte"
  import Modal from "src/partials/Modal.svelte"
  import OverflowMenu from "src/partials/OverflowMenu.svelte"
  import CopyValue from "src/partials/CopyValue.svelte"
  import Reaction from "src/app/shared/Reaction.svelte"
  import PersonBadge from "src/app/shared/PersonBadge.svelte"
  import PersonBadgeSmall from "src/app/shared/PersonBadgeSmall.svelte"
  import RelayCard from "src/app/shared/RelayCard.svelte"
  import NoteReaction from "src/app/shared/NoteReaction.svelte"
  import {router} from "src/app/router"
  import type {Event} from "src/engine"
  import {
    env,
    mute,
    unmute,
    canSign,
    session,
    Publisher,
    publishDeletion,
    getUserRelayUrls,
    getPublishHints,
    publishReaction,
    processZap,
    displayRelay,
    getEventHints,
    isEventMuted,
  } from "src/engine"

  export let note: Event
  export let reply
  export let showMuted
  export let showEntire
  export let addToContext
  export let removeFromContext
  export let reactions
  export let replies
  export let zaps
  export let zapper

  const nevent = nip19.neventEncode({id: note.id, relays: getEventHints(note)})
  const muted = isEventMuted.derived($isEventMuted => $isEventMuted(note, true))
  const interpolate = (a, b) => t => a + Math.round((b - a) * t)
  const zapsTotal = tweened(0, {interpolate})
  const repliesCount = tweened(0, {interpolate})
  const reactionOptions = Tags.from(note).type("reaction").values().all()
  const emojiDefinitions = fromPairs(Tags.from(note).type("emoji").drop(1).all())

  //const report = () => router.at("notes").of(note.id, {relays: getEventHints(note)}).at('report').qp({pubkey: note.pubkey}).open()

  const label = () =>
    router
      .at("notes")
      .of(note.id, {relays: getEventHints(note)})
      .at("label")
      .open()

  const quote = () =>
    router
      .at("notes/create")
      .cx({quote: note, relays: getEventHints(note)})
      .open()

  const unmuteNote = () => unmute(note.id)

  const muteNote = () => mute("e", note.id)

  const createReaction = async content => {
    const tags = []
    const emoji = content.replace(/:/g, "")

    if (emojiDefinitions[emoji]) {
      tags.push(["emoji", emoji, emojiDefinitions[emoji]])
    }

    const pub = await publishReaction(note, content, tags)

    addToContext(pub.event)
  }

  const deleteReaction = e => {
    publishDeletion([getIdOrNaddr(e)])

    removeFromContext(e)
  }

  const startZap = () =>
    router
      .at("people")
      .of(note.pubkey, {relays: getPublishHints(note)})
      .at("zap")
      .qp({eid: note.id, lnurl: zapper.lnurl})
      .open()

  const broadcast = () => {
    const relays = getUserRelayUrls("write")
    const event = asNostrEvent(note)

    Publisher.publish({event, relays})

    toast.show("info", "Note has been re-published!")
  }

  const setFeedRelay = url =>
    router
      .fromCurrent()
      .cx({relays: [url]})
      .open()

  let zap
  let showDetails = false
  let actions = []

  $: disableActions = !$canSign || ($muted && !showMuted)

  $: zap = zap || find(pathEq(["request", "pubkey"], $session?.pubkey), zaps)

  $: $zapsTotal =
    sum(
      pluck(
        // @ts-ignore
        "invoiceAmount",
        zap ? zaps.filter(n => n.id !== zap?.id).concat(processZap(zap, zapper)) : zaps
      )
    ) / 1000

  $: canZap = zapper && note.pubkey !== $session?.pubkey
  $: $repliesCount = replies.length

  $: {
    actions = []

    actions.push({label: "Quote", icon: "quote-left", onClick: quote})
    actions.push({label: "Tag", icon: "tag", onClick: label})
    //actions.push({label: "Report", icon: "triangle-exclamation", onClick: report})

    if ($muted) {
      actions.push({label: "Unmute", icon: "microphone", onClick: unmuteNote})
    } else {
      actions.push({label: "Mute", icon: "microphone-slash", onClick: muteNote})
    }

    if ($env.FORCE_RELAYS.length === 0) {
      actions.push({label: "Broadcast", icon: "rss", onClick: broadcast})

      actions.push({
        label: "Details",
        icon: "info",
        onClick: () => {
          showDetails = true
        },
      })
    }
  }
</script>

<div class="flex justify-between text-gray-1" on:click|stopPropagation>
  <div class="flex">
    <button
      class={cx("relative w-16 pt-1 text-left transition-all hover:pb-1 hover:pt-0", {
        "pointer-events-none opacity-50": disableActions,
      })}
      on:click={reply.start}>
      <i class="fa fa-reply cursor-pointer" />
      {$repliesCount}
    </button>
    {#if $env.ENABLE_ZAPS}
      <button
        class={cx("relative w-16 pt-1 text-left transition-all hover:pb-1 hover:pt-0", {
          "pointer-events-none opacity-50": disableActions || !canZap,
          "text-accent": zap,
        })}
        on:click={startZap}>
        <i class="fa fa-bolt cursor-pointer" />
        {formatSats($zapsTotal)}
      </button>
    {/if}
    {#each reactionOptions as content}
      <NoteReaction
        {note}
        {content}
        {reactions}
        {disableActions}
        {createReaction}
        {deleteReaction}
        {emojiDefinitions} />
    {:else}
      <NoteReaction
        content="+"
        isMatch={e => isLike(e.content)}
        {note}
        {reactions}
        {disableActions}
        {createReaction}
        {deleteReaction}
        {emojiDefinitions} />
    {/each}
  </div>
  <div class="flex items-center">
    {#if $env.FORCE_RELAYS.length === 0}
      <!-- Mobile version -->
      <div
        style="transform: scale(-1, 1)"
        class="absolute right-0 top-0 m-3 grid grid-cols-3 gap-2 sm:hidden">
        {#each sortBy(identity, note.seen_on) as url, i}
          <div class={`cursor-pointer order-${3 - (i % 3)}`}>
            <ColorDot value={url} on:click={() => setFeedRelay(url)} />
          </div>
        {:else}
          <div class="cursor-pointer order-3">
            <ColorDot value={LOCAL_RELAY_URL} />
          </div>
        {/each}
      </div>
      <!-- Desktop version -->
      <div
        class={cx("hidden transition-opacity sm:flex", {
          "opacity-0 group-hover:opacity-100": !showEntire,
        })}>
        {#each sortBy(identity, note.seen_on) as url, i}
          <Popover triggerType="mouseenter" interactive={false}>
            <div slot="trigger" class="cursor-pointer p-1">
              <ColorDot value={url} on:click={() => setFeedRelay(url)} />
            </div>
            <div slot="tooltip">{displayRelay({url})}</div>
          </Popover>
        {:else}
          <Popover triggerType="mouseenter" interactive={false}>
            <div slot="trigger" class="cursor-pointer p-1">
              <ColorDot value={LOCAL_RELAY_URL} />
            </div>
            <div slot="tooltip">Loaded from cache</div>
          </Popover>
        {/each}
      </div>
    {/if}
    <div class="ml-1 sm:ml-2">
      <OverflowMenu {actions} />
    </div>
  </div>
</div>

{#if showDetails}
  <Modal
    onEscape={() => {
      showDetails = false
    }}>
    <Content>
      {#if zaps.length > 0}
        <h1 class="staatliches text-2xl">Zapped By</h1>
        <div class="grid grid-cols-2 gap-2">
          {#each zaps as zap}
            <div class="flex flex-col gap-1">
              <PersonBadge pubkey={zap.request.pubkey} />
              <span class="ml-16 text-sm text-gray-5"
                >{formatSats(zap.invoiceAmount / 1000)} sats</span>
            </div>
          {/each}
        </div>
      {/if}
      {#if reactions.length > 0}
        <h1 class="staatliches text-2xl">Reactions</h1>
        <div class="grid grid-cols-2 gap-2">
          {#each reactions as reaction}
            <div class="flex gap-2">
              <Reaction class="w-16" event={reaction} />
              <PersonBadgeSmall pubkey={reaction.pubkey} />
            </div>
          {/each}
        </div>
      {/if}
      <h1 class="staatliches text-2xl">Relays</h1>
      <p>This note was found on {quantify(note.seen_on.length, "relay")} below.</p>
      <div class="flex flex-col gap-2">
        {#each note.seen_on as url}
          <RelayCard relay={{url}} />
        {/each}
      </div>
      <h1 class="staatliches text-2xl">Details</h1>
      <CopyValue label="Link" value={toNostrURI(nevent)} />
      <CopyValue label="Event ID" encode={nip19.noteEncode} value={note.id} />
      <CopyValue label="Event JSON" value={JSON.stringify(note)} />
    </Content>
  </Modal>
{/if}
