<script lang="ts">
  import {nth} from "ramda"
  import {Tags} from "src/util/nostr"

  export let event

  const code = nth(1, event.content.match(/:(\w+):/) || [])
  const src = Tags.from(event).type("emoji").equals(code).drop(2).first()
</script>

<div class={$$props.class}>
  {#if src}
    <img class="h-6 w-6" {src} />
  {:else}
    {event.content}
  {/if}
</div>
