<script lang="ts">
  import cx from "classnames"
  import {find, propEq} from "ramda"
  import {tweened} from "svelte/motion"
  import {pubkey} from "src/engine"

  export let note
  export let content
  export let reactions
  export let disableActions
  export let createReaction
  export let deleteReaction
  export let emojiDefinitions
  export let isMatch = e => e.content === content

  const emojiKey = content.replace(/:/g, "")

  const count = tweened(0, {interpolate: (a, b) => t => a + Math.round((b - a) * t)})

  const onClick = () => {
    if (userReaction) {
      deleteReaction(userReaction)
    } else {
      createReaction(content)
    }
  }

  $: matchingReactions = reactions.filter(isMatch)
  $: userReaction = find(propEq("pubkey", $pubkey), matchingReactions)
  $: $count = matchingReactions.length
</script>

<button
  class={cx(
    "relative flex w-16 items-center gap-1 pt-1 text-left transition-all hover:pb-1 hover:pt-0",
    {
      "pointer-events-none opacity-50": disableActions || note.pubkey === $pubkey,
      "text-accent": userReaction,
    }
  )}
  on:click={onClick}>
  {#if emojiDefinitions[emojiKey]}
    <img
      src={emojiDefinitions[emojiKey]}
      class={cx("cursor-pointer", {
        "fa-beat fa-beat-custom": userReaction,
      })} />
  {:else if content === "+"}
    <i class="fa fa-heart" />
  {:else}
    {content}
  {/if}
  {$count}
</button>
