<script lang="ts">
  import "emoji-picker-element"
  import {onMount} from "svelte"
  import {without, uniq} from "ramda"
  import Chip from "src/partials/Chip.svelte"
  import Popover from "src/partials/Popover.svelte"

  export let value

  let picker

  const addEmoji = emoji => {
    value = uniq(value.concat(emoji))
  }

  const removeEmoji = emoji => {
    value = without([emoji], value)
  }

  onMount(() => {
    picker.addEventListener("emoji-click", event => addEmoji(event.detail.unicode))
  })
</script>

<div
  class="shadow-inset w-full rounded-full border border-solid border-gray-3 bg-input pt-1 text-black"
  class:px-1={value.length > 0}
  class:px-4={value.length === 0}>
  {#each value as emoji}
    <Chip theme="light" class="mb-1 mr-1" onRemove={() => removeEmoji(emoji)}>{emoji}</Chip>
  {/each}
  <Popover theme="transparent" class="inline">
    <span slot="trigger" class="mb-1 inline-block cursor-pointer py-1">
      <i class="fa fa-circle-plus" />
    </span>
    <emoji-picker
      slot="tooltip"
      class="overflow-hidden rounded-2xl border border-solid border-gray-5"
      bind:this={picker} />
  </Popover>
</div>
